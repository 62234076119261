var assetsLoader = require('assets-loader');


// window.onload = function () {

// var i=['js/vendor/jquery-1.11.2.min.js',
//   'js/vendor/tweenmax_1.19.1_92cf05aba6ca4ea5cbc62b5a7cb924e3_min.js',
//   'js/vendor/timelinemax_1.19.1_66ede2aefd4a70acddf9ff739530d923_min.js',
//   'js/vendor/ScrollMagic.min.js',
//   'js/vendor/animation.gsap.js',
//   'js/vendor/MorphSVGPlugin.min.js',
//   'js/vendor/debug.addIndicators.js',
//   'js/vendor/picturefill.min.js',
// ];


// var o=document;
//   for(var e=0;e<i.length;e++){
//     if (navigator.appName.indexOf('Microsoft')==0)
//       new Image().src=i[e];
//     else {
//       var t = o.createElement('object');
//       t.data=i[e]
//       t.width=0;
//       t.height=0;
//       o.body.appendChild(t);
//     }
//   }
// };


var loader = assetsLoader({
        assets: [
          'img/maps/stage1.jpg',
          'img/maps/stage2.jpg',
          'img/maps/stage3.jpg',
          'img/maps/stage4.jpg',
          'img/maps/stage5.jpg',
          'img/maps/stage6.jpg',
          'img/maps/stage7.jpg',
          'img/maps/stage8.jpg',
          'img/maps/stage9.jpg',
          'img/maps/stage10.jpg',
          'img/maps/stage11.jpg',
          'img/maps/stage12.jpg',
          'img/maps/stage13.jpg',
          'img/maps/stage14.jpg',
          'img/maps/stage15.jpg',
          'img/maps/stage16.jpg',
          'img/maps/stage17.jpg',
          'img/maps/stage18.jpg',
          'img/maps/stage19.jpg',
          'img/maps/stage20.jpg',
          'img/features/features_bg_img.jpg',
          'img/features/looking_forward_1.jpg',
          'img/features/looking_forward_2.jpg',
          'img/features/turn_right_1.jpg',
          'img/features/turn_right_2.jpg',
          'img/flag/argentina_flag.png',
          'img/flag/bolivia_flag.png',
          'img/flag/chile_flag.png',
          'img/flag/colombia_flag.png',
          'img/flag/costarica_flag.png',
          'img/flag/ecuador_flag.png',
          'img/flag/elsalvador_flag.png',
          'img/flag/guatemala_flag.png',
          'img/flag/honduras_flag.png',
          'img/flag/mexico_flag.png',
          'img/flag/nicaragua_flag.png',
          'img/flag/panama_flag.png',
          'img/flag/paraguay_flag.png',
          'img/flag/peru_flag.png',
          'img/flag/uruguay_flag.png',
          '/img/section/section_1/1-Porsche-Montevideo-Exterior-altura.jpg',
          '/img/section/section_1/2-Arranque-Etapa-1-Montevideo-Uruguay.jpg',
          '/img/section/section_1/3-Preparando-Utillajes-Montevideo-Porsche-Dealer.jpg',
          '/img/section/section_1/4-Firma-de-Auto-Periodistas-Montevideo-Uruguay.jpg',
          '/img/section/section_1/5-Arranque-Etapa-1-Montevideo-Uruguay-3.jpg',
          '/img/section/section_1/6-Mercedes-Frontera-Uruguay-2.jpg',
          '/img/section/section_1/7-Puente-Garzon-Uruguay-6.jpg',
          '/img/section/section_1/8-Staff-en-Control-Montevideo-Uruguay.jpg',
          '/img/section/section_1/9-Subida-al-Barco-Uruguay-Argentina.jpg',
          '/img/section/section_1/10-Turistas-Colombianos-en-Punta-del-Este.jpg',
          '/img/section/section_1/porsche-center-nordenwagen-argentina.jpg',
          '/img/section/section_1/tim-bravo-conferencia-de-prensa-PC-buenos-aires.jpg',
          '/img/section/section_2/01-firma-equipo-de-prensa.jpg',
          '/img/section/section_2/02-los-lagos-via-patagonia-argentina.jpg',
          '/img/section/section_2/03-restaurante-boliche-vIejo-patagonia-argentina.jpg',
          '/img/section/section_2/04-rio-negro-patagonia-argentina.jpg',
          '/img/section/section_2/05-cerro-otto.jpg',
          '/img/section/section_2/06-lago-correntoso.jpg',
          '/img/section/section_2/07-san-martin-de-los-andes.jpg',
          '/img/section/section_2/08-villa-la-angostura.jpg',
          '/img/section/section_2/09-puente-collon-cura.jpg',
          '/img/section/section_2/10-lagos-bariloche.jpg',
          '/img/section/section_2/11-lago-nahuel-huapi.jpg',
          '/img/section/section_2/12-de-aeropuerto-de-bariloche.jpg',
          '/img/section/section_3/01-firma-de-alberto-alvarez-nicholson.jpg',
          '/img/section/section_3/02-lago-nahuel-huapi.jpg',
          '/img/section/section_3/03-paso-cardenal-antonio-samore.jpg',
          '/img/section/section_3/04-paso-fronterizo-argentina-chile-antonio-samore.jpg',
          '/img/section/section_3/05-plaza-central-bariloche.jpg',
          '/img/section/section_3/06-rancho-ruta-5-osorno-chile.jpg',
          '/img/section/section_3/07-staff.jpg',
          '/img/section/section_3/08-federico-sanchez-torres.jpg',
          '/img/section/section_3/09-cayman-gt4-challenge.jpg',
          '/img/section/section_3/10-cayman-gt4-challenge.jpg',
          '/img/section/section_3/11-cayman-gt4-challenge.jpg',
          '/img/section/section_4/01_firmas_prensa.jpg',
          '/img/section/section_4/02_salto_de_lajas_chile.jpg',
          '/img/section/section_4/03_salto_de_lajas_chile.jpg',
          '/img/section/section_4/04_navegacion_porsche_cayenne.jpg',
          '/img/section/section_4/05_cruce-fronterizo_de_los_andes_chile_argentina.jpg',
          '/img/section/section_4/06_paso_fronterizo_chile_argentina_puente_del_inca.jpg',
          '/img/section/section_4/07_paso_fronterizo_chile_argentina_puente_del_inca.jpg',
          '/img/section/section_4/08_vinedos_pulenta_estate_mendoza_argentina.jpg',
          '/img/section/section_4/09_vinedos_pulenta_estate_mendoza_argentina.jpg',
          '/img/section/section_4/10_vinedos_pulenta_estate_mendoza_argentina.jpg',
          '/img/section/section_4/11_vinedos_pulenta_estate_mendoza_argentina.jpg',
          '/img/section/section_4/12_vinedos_pulenta_estate_mendoza_argentina.jpg',
          '/img/section/section_5/01_claudio_munoz_periodista.jpg',
          '/img/section/section_5/02_rio_parana.jpg',
          '/img/section/section_5/03_antiguo_molino_harinero_san_jose.jpg',
          '/img/section/section_5/04_rumbo_a_asuncion.jpg',
          '/img/section/section_5/05_tuneles_de_arboles_rumbo_a_ciudad_del_este.jpg',
          '/img/section/section_5/06_hidroelectrica_itaipu.jpg',
          '/img/section/section_5/07_hidroelectrica_itaipu.jpg',
          '/img/section/section_5/08_conferencia_de_prensa_diesa_ciudad_del_este.jpg',
          '/img/section/section_5/09_cataratas_ciudad_del_este.jpg',
          '/img/section/section_5/10_ciudad_del_este.jpg',
          '/img/section/section_5/11_estadio_de_futbol_defensores_del_chaco.jpg',
          '/img/section/section_5/12_oficina_presidencial_palacio_de_los_lopez.jpg',
          '/img/section/section_5/new_01_hidroelectrica_itaipu.jpg',
          '/img/section/section_5/new_02_hidroelectrica_itaipu.jpg',
          '/img/section/section_5/new_03_cascadas_salto_ciudad_del_este.jpg',
          '/img/section/section_5/new_04_costanera_encarnacion_paraguay.jpg',
          '/img/section/section_6/01_porsche_center_ciuad_del_este.jpg',
          '/img/section/section_6/02_raul_velilla.jpg',
          '/img/section/section_6/03_revista_zapping.jpg',
          '/img/section/section_6/04_rumbo_a_ciudad_del_este.jpg',
          '/img/section/section_6/05_cataratas_en_ciudad_del_este.jpg',
          '/img/section/section_7/01_canal_isuto_santa_cruz.jpg',
          '/img/section/section_7/02_El_Cristo_Bandera_Santa_Cruz_Y_Bandera_Boliviana.jpg',
          '/img/section/section_7/03_llegando_a_santa_cruz_el_cristo.jpg',
          '/img/section/section_7/04_luxor_porsche_center.jpg',
          '/img/section/section_7/05_santa_cruz_plagado_de_publicidad_porsche.jpg',
          '/img/section/section_8/01_Porsche_Center_de_Santa_Cruz_de_la_Sierra.jpg',
          '/img/section/section_8/03_Carretera_Santa_Cruz_de_la_Sierra-Cochabamba.jpg',
          '/img/section/section_8/04_Carretera_Santa_Cruz_de_la_Sierra-Cochabamba.jpg',
          '/img/section/section_8/05_El_Cayenne_S_se_despide_de_Cochabamba.jpg',
          '/img/section/section_8/06_Villa_Tunari_departamento_de_Cochabamba.jpg',
          '/img/section/section_8/08_Cristo_de_la_Concordia_Cochabamba.jpg',
          '/img/section/section_8/09_Carretera_Cochabamba.jpg',
          '/img/section/section_8/Expedicion-Cayenne_Ricky_Porsche-10.jpg',
          '/img/section/section_8/Expedicion-Cayenne_Ricky_Porsche-93.jpg',
          '/img/section/section_8/Expedicion-Cayenne_Ricky_Porsche-149.jpg',
          '/img/section/section_8/Expedicion-Cayenne_Ricky_Porsche-165.jpg',
          '/img/section/section_8/Expedicion-Cayenne_Ricky_Porsche-189.jpg',
          '/img/section/section_9/13_Nasca.jpg',
          '/img/section/section_9/14_Nasca.jpg',
          '/img/section/section_9/15_Nasca.jpg',
          '/img/section/section_9/16_Nasca.jpg',
          '/img/section/section_9/17_Nasca.jpg',
          '/img/section/section_9/18_Nasca.jpg',
          '/img/section/section_9/19_Nasca.jpg',
          '/img/section/section_9/20_Nasca.jpg',
          '/img/section/section_9/new_fundo-lambayeque.jpg',
          '/img/section/section_9/new_parada_a_comer_rumbo_a_trujollo.jpg',
          '/img/section/section_9/new_trayecto_arequipa_nasca_peru.jpg',
          '/img/section/section_9/new_trayecto_arequipa_nasca_peru2.jpg',
          '/img/section/section_10/1_arranque_porsche_lima.jpg',
          '/img/section/section_10/2_chimborazo_lodge5.jpg',
          '/img/section/section_10/3_chimborazo_lodge.jpg',
          '/img/section/section_10/4_botas_de_reinhold_messner.jpg',
          '/img/section/section_10/5_chimborazo_lodge.jpg',
          '/img/section/section_10/6_chimborazo_lodge.jpg',
          '/img/section/section_10/7_chimborazo_lodge.jpg',
          '/img/section/section_10/8_chimborazo_lodge.jpg',
          '/img/section/section_10/9_eric_gallardo_con_marco_cruz.jpg',
          '/img/section/section_10/10_ecuador.jpg',
          '/img/section/section_10/11_primera_iglesia_de_ecuador_santisima_virgen_de_balbanera.jpg',
          '/img/section/section_10/12_IMG_0528.jpg',
          '/img/section/section_11/1_porsche_center_quito.jpg',
          '/img/section/section_11/2_mitad_del_mundo_ecuador.jpg',
          '/img/section/section_11/3_mitad_del_mundo_ecuador2.jpg',
          '/img/section/section_11/4_mitad_del_mundo_ecuador3.jpg',
          '/img/section/section_11/5_mitad_del_mundo_ecuador4.jpg',
          '/img/section/section_11/6_mitad_del_mundo_ecuador5.jpg',
          '/img/section/section_11/7_mitad_del_mundo_ecuador6.jpg',
          '/img/section/section_11/8_mitad_del_mundo_ecuador7.jpg',
          '/img/section/section_11/9_mitad_del_mundo_ecuador8.jpg',
          '/img/section/section_11/10_mitad_del_mundo.jpg',
          '/img/section/section_11/11_unasur_y_monumento_mitad_del_mundo.jpg',
          '/img/section/section_11/12_porsche_center_cali.jpg',
          '/img/section/section_12/1_andres_carne_de_res.jpg',
          '/img/section/section_12/2_atardecer_monserrate_bogota.jpg',
          '/img/section/section_12/3_carlos_munera.jpg',
          '/img/section/section_12/4_cayenne_transiveria.jpg',
          '/img/section/section_12/5_etapa_con_clientes_bogota_medellin.jpg',
          '/img/section/section_12/6_firma_de_magnus.jpg',
          '/img/section/section_12/7_iglesia_de_buga.jpg',
          '/img/section/section_12/8_magnus_en_monserrate_bogota.jpg',
          '/img/section/section_12/9_magnus_walker_con_cayenne_transiveria.jpg',
          '/img/section/section_12/10_monserrate_bogota.jpg',
          '/img/section/section_12/11_parada_en_buga.jpg',
          '/img/section/section_12/12_team_cayenne_con_magnus_walker_bogota.jpg',
          '/img/section/section_13/1_atardecer_barranquilla.jpg',
          '/img/section/section_13/1_cars.jpg',
          '/img/section/section_13/2_atardecer_en_cartagena.jpg',
          '/img/section/section_13/2_cars.jpg',
          '/img/section/section_13/3_cars.jpg',
          '/img/section/section_13/3_ciudad_amurallada_cartagena.jpg',
          '/img/section/section_13/4_cars.jpg',
          '/img/section/section_13/4_ciudad_amurallada_cartagena.jpg',
          '/img/section/section_13/5_costanera_cartagena.jpg',
          '/img/section/section_13/6_entrando_a_ciudad_amurallada_en_cartagena.jpg',
          '/img/section/section_13/7_entrando_a_ciudad_amurallada_en_cartagena.jpg',
          '/img/section/section_13/8_parapente_en-matasanos.jpg',
          '/img/section/section_13/9_parapente_en_matasanos.jpg',
          '/img/section/section_13/10_playa_cartagena.jpg',
          '/img/section/section_13/11_porsche_center_barranquilla.jpg',
          '/img/section/section_13/12_puesta_del_sol_en_cartagena.jpg',
          '/img/section/section_14/birdseye.jpg',
          '/img/section/section_14/boquete.jpg',
          '/img/section/section_14/buenaventura-panama.jpg',
          '/img/section/section_14/carls-nordstrom-periodista.jpg',
          '/img/section/section_14/cocodrilos-en-rio-tarcoles.jpg',
          '/img/section/section_14/container-canal-de-panama.jpg',
          '/img/section/section_14/evento-en-valle-escondido-boquete-panama.jpg',
          '/img/section/section_14/gabriela-sanchez-revista-forbes-panama.jpg',
          '/img/section/section_14/loma-campana-y-vista-canal-de-panama.jpg',
          '/img/section/section_14/playa-buenaventura-panama.jpg',
          '/img/section/section_14/porsche-center-costa-rica.jpg',
          '/img/section/section_14/sr-jorge-sapi-raul-civiello-andrea-perdomo-con-staff-expedicion-cayenne.jpg',
          '/img/section/section_15/san_jose_costa_rica_2.jpg',
          '/img/section/section_15/san_jose_costa_rica_3.jpg',
          '/img/section/section_15/san_jose_costa_rica_4.jpg',
          '/img/section/section_15/san_jose_costa_rica_5.jpg',
          '/img/section/section_15/san_jose_costa_rica.jpg',
          '/img/section/section_15/surfistas.jpg',
          '/img/section/section_16/parroquia-san-miguel-arcangel-zarcero.jpg',
          '/img/section/section_16/parroquia-san-miguel-arcangel-zarcero2.jpg',
          '/img/section/section_16/volcan-momotombo-nicaragua.jpg',
          '/img/section/section_16/volcan-momotombo-nicaragua2.jpg',
          '/img/section/section_16/volcan-momotombo-nicaragua3.jpg',
          '/img/section/section_16/volcan-momotombo-nicaragua4.jpg',
          '/img/section/section_16/volcan-momotombo-nicaragua5.jpg',
          '/img/section/section_16/volcan-momotombo-nicaragua6.jpg',
          '/img/section/section_17/camino-a-san-salvador.jpg',
          '/img/section/section_17/camino-a-san-salvador2.jpg',
          '/img/section/section_17/ciudad-de-san-salvador.jpg',
          '/img/section/section_17/IMG_3701.jpg',
          '/img/section/section_17/la-libertad-el-salvador.jpg',
          '/img/section/section_17/la-libertad-el-salvador2.jpg',
          '/img/section/section_17/mirador-san-salvador-y-volcan-san-vicente-de-fondo.jpg',
          '/img/section/section_17/puente-de-oro-rio-lempa-el-salvador.jpg',
          '/img/section/section_17/puente-melara-el-salvador.jpg',
          '/img/section/section_17/volcan-de-san-vicente-el-salvador3.jpg',
          '/img/section/section_17/volcan-san-vicente-el-salvador.jpg',
          '/img/section/section_17/wilmer-merino-revista-motor-de-la-prensa-grafica.jpg',
          '/img/section/section_18/foto-puente-del-oro-lempa-el-salvador.jpg',
          '/img/section/section_18/camion-antiguo.jpg',
          '/img/section/section_18/llegando-al-coktail-en-guatemala.jpg',
          '/img/section/section_18/rumbo-a-guatemala.jpg',
          '/img/section/section_18/GOPR2963.jpg',
          '/img/section/section_18/bienvenida-al-club-porsche-el-salvador-en-grupo-q-porsche-Center-el-salvador.jpg',
          '/img/section/section_18/cayenne-s-con-el-volcan-de-Izalco-de-fondo.jpg',
          '/img/section/section_18/club-porsche-acompanando-a-la-expedicion.jpg',
          '/img/section/section_18/club-porsche-el-salvador-en-ruta.jpg',
          '/img/section/section_18/volcan-de-izalco-el-salvador.jpg',
          '/img/section/section_18/atardecer-guatemala.jpg',
          '/img/section/section_18/lago-el-comendador.jpg',
          '/img/section/section_19/cayenne-estrella-acompanado-de-clon-guatemalteco-acompanandolo-en-la-expedicion.jpg',
          '/img/section/section_19/expedicion-cayenne-en-la-antigua-guatemala-volcan-de-agua-de-fondo.jpg',
          '/img/section/section_19/gopro.jpg',
          '/img/section/section_19/volcan-de-humo-expidiendo-vapor-guatemala.jpg',
          '/img/section/section_19/porsche-club-guatemala.jpg',
          '/img/section/section_19/arco-del-carmen-san-cristobal.jpg',
          '/img/section/section_19/camino-al-restaurante-para-recibir-a-la-prensa-en-san-cristobal-mexico.jpg',
          '/img/section/section_19/entrada-a-mexico-por-guatemala.jpg',
          '/img/section/section_19/camilo-gilbert.jpg',
          '/img/section/section_19/canon-del-sumidero.jpg',
          '/img/section/section_19/paulina-dorantes.jpg',
          '/img/section/section_19/san-cristobal.jpg',
          // '/img/section/section_13/.jpg',
        ]

      })
      .on('error', function(error) {
        console.error(error);
      })
      .on('progress', function(progress) {
         countdown_loader(progress);
      })
      .on('complete', function(assets) {
        console.log('assets ', assets)
        countdown_complete();
      })
      .start();





      function countdown_loader(progress){

        // var progress_mileage = Math.ceil(progress * 18855);
        // const preloader_stage_dom = document.querySelector('.mileage_counter');

        // progress_mileage = progress_mileage.toString();

        // console.log(progress_mileage.length)


        // if(progress_mileage.length <= 3){
        //   var current_mileage = `<span>0</span><span>0</span><span>.</span><span>${progress_mileage[0]}</span><span>${progress_mileage[1]}</span><span>${progress_mileage[2]}</span><span>K</span> <span>M</span>`;
        // }

        // if(progress_mileage.length == 4){
        //   var current_mileage = `<span>0</span><span>${progress_mileage[0]}</span><span>.</span><span>${progress_mileage[1]}</span><span>${progress_mileage[2]}</span><span>${progress_mileage[3]}</span><span>K</span> <span>M</span>`;
        // }

        // if(progress_mileage.length == 5){
        //     var current_mileage = `<span>${progress_mileage[0]}</span><span>${progress_mileage[1]}</span><span>.</span><span>${progress_mileage[2]}</span><span>${progress_mileage[3]}</span><span>${progress_mileage[4]}</span><span>K</span> <span>M</span>`;
        // }






        // console.log(current_mileage);

        // preloader_stage_dom.innerHTML = current_mileage;


      }




      // console.log('test out preloader')



      function countdown_complete(){
        const preloader_stage_dom = document.querySelector('.preloader_stage');
        const body = document.querySelector('body');
        const html = document.querySelector('html');

        preloader_stage_dom.classList.add('fade_out_preloader');

        setTimeout(function(){
          preloader_stage_dom.style.display = "none";
          body.style.overflow = "visible";
          html.style.overflow = "visible";

         }, 500);
      }




// Example:



















